import React, { useState, useEffect } from "react";
import "./PositionDetail.css";
import "../index.css";
import "../firebase";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  addDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import Fab from "@mui/material/Fab";
import Draggable from "react-draggable";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ArrowPathIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  ClockIcon,
  HomeIcon,
  ChevronLeftIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Document, Page, pdfjs } from "react-pdf";
import { SizeMe } from "react-sizeme";
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [{ name: "Dashboard", href: "/", current: true }];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PerformanceDetailed = () => {
  const db = getFirestore();
  const location = useLocation();
  const navigate = useNavigate();
  const feedbackDetails = location.state.feedbackDetails
    ? location.state.feedbackDetails
    : "";
  const TriageDetails = location.state.TriageDetails;
  const userEmail = location.state.userEmail;
  const triageScoreForEmail = location.state.triageScoreForEmail;
  const ResumeForEmail = location.state.ResumeForEmail;
  const Position = location.state.Position;
  const feedbackPrct = location.state.feedbackPrct;
  const QAs = location.state.QAs ? location.state.QAs : "";

  const questionsAndAnswers =
    QAs !== ""
      ? QAs.split(/Question \d+/)
          .slice(1)
          .map((item) => {
            const [question, answer] = item.split(/Answer \d+/);
            return { question: `${question}`, answer: `${answer}` };
          })
      : 0;

  console.log("****Amine1", feedbackDetails);
  console.log("****Amine2", QAs);
  console.log("****Amine3", questionsAndAnswers);

  const criteriaArray = TriageDetails.split(/Criteria \d+/);

  const metricArray =
    feedbackDetails !== "" ? feedbackDetails.split(/Metric \d+/) : 0;

  const fs = getStorage();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showInputField, setShowInputField] = useState(false);
  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        // Replace 'your-pdf-file.pdf' with the actual path to your PDF file in Firebase Storage
        const pdfRef = ref(
          fs,
          "gs://mockquest.appspot.com/savedPDF/" + userEmail
        );
        console.log(userEmail);
        const pdfUrl = await getDownloadURL(pdfRef);
        setPdfUrl(pdfUrl);
      } catch (error) {
        console.error("Error fetching PDF URL:", error.message);
      }
    };

    fetchPdfUrl();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  console.log(criteriaArray);

  return (
    <div className="relative isolate min-h-full">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      {/* <div className="min-h-full"> */}
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-7 "
                      src="https://i.ibb.co/GnW0bz3/ico-white.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "flex items-center rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <HomeIcon
                            className="h-5 w-5 mr-2"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <button
                      type="button"
                      className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.imageUrl}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium leading-none text-gray-400">
                      {user.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <header className="bg-white shadow flex justify-between items-center px-4 py-6">
          <div className="flex items-center space-x-2">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon
                className="h-9 w-9 cursor-pointer"
                aria-hidden="true"
              />
            </div>
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Performances :{" "}
              {Position.charAt(0).toUpperCase() + Position.slice(1)}
            </h1>
          </div>
        </header>
      </div>

      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-2 gap-6 sm:px-1 sm:py-[5vh] lg:max-w-[90vw]">
            <div className="rounded-xl bg-gray-900 shadow-xl ring-3 ring-gray-600/30 border ring-gray-200 p-6 text-white">
              <div className="border-b border-white-200 pt-4">
                <h2 className="text-2xl font-semibold mb-4">Resume</h2>
              </div>
              <div className="h-[60vh] overflow-y-auto mt-4">
                <SizeMe>
                  {({ size }) => (
                    <Document
                      file={pdfUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={1} width={size.width} />
                    </Document>
                  )}
                </SizeMe>
              </div>
            </div>

            <div className="rounded-xl shadow-xl ring-3 ring-gray-600/30 border border-gray-300 p-6 text-white">
              <div className="border-b border-gray-900 pt-4">
                <h2 className="text-2xl font-semibold mb-4 text-gray-900">
                  Resume criteria analysis
                </h2>
              </div>
              <div className="h-[60vh] overflow-y-auto">
                <div className="mt-4 text-gray-500">
                  {criteriaArray.map(
                    (line, index) =>
                      index !== 0 && (
                        <React.Fragment key={index}>
                          <b
                            className={
                              line.includes("NOT MATCHED")
                                ? "text-red-500 text-l font-bold"
                                : "text-green-500 text-l font-bold"
                            }
                          >
                            {"Criteria " + index + " :"}
                          </b>
                          <p className="">
                            {line.split(":").slice(1).join(":").trim()}
                          </p>
                        </React.Fragment>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto grid max-w-2xl grid-cols-2 gap-6 sm:px-1 lg:max-w-[90vw]">
            <div className="rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 p-6 text-white">
              <div className="border-b border-white-200 pt-4">
                <h2 className="text-2xl font-semibold mb-4">
                  Interview transcription
                </h2>
              </div>
              <div className="h-[60vh] overflow-y-auto text-white-500">
                <div className="mt-4 text-white-500 ">
                  {questionsAndAnswers !== 0 ? (
                    <>
                      {questionsAndAnswers.map((item, index) => (
                        <>
                          <p key={index} className="text-l font-bold">
                            {"Q" + (index + 1) + item["question"]}
                          </p>
                          <p key={index}>
                            {">>A" + (index + 1) + item["answer"]}
                          </p>
                          <div className="border-t border-white-200 border-dotted w-24 mb-2 mt-2" />
                        </>
                      ))}
                    </>
                  ) : (
                    <p className=" ">
                      Candidate didn't pass the interview yet..
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="rounded-xl shadow-xl ring-3 ring-gray-600/30 border border-gray-300 p-6 text-white">
              <div className="border-b border-gray-900 pt-4">
                <h2 className="text-2xl font-semibold mb-4 text-gray-900">
                  Interview metrics analysis
                </h2>
              </div>
              <div className="h-[60vh] overflow-y-auto">
                <div className="mt-4 text-gray-500">
                  {metricArray !== 0 ? (
                    <>
                      {metricArray.map(
                        (line_, index_) =>
                          index_ !== 0 && (
                            <React.Fragment key={index_}>
                              <b className="text-l font-bold">
                                {"Metric " + index_ + " :"}
                              </b>
                              <p className="">
                                {line_.split(":").slice(1).join(":").trim()}
                              </p>
                            </React.Fragment>
                          )
                      )}
                    </>
                  ) : (
                    <p className="">
                      Candidate didn't pass the interview yet..
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      
        <Fab
          color="primary"
          aria-label="edit"
          style={{ position: "fixed", bottom: "20px", right: "20px" }}
          onClick={() => {
            setShowInputField(!showInputField);
          }}
        >
          <PencilIcon className="h-6 w-6" aria-hidden="true" color="white" />
        </Fab>
        {showInputField && (
          <div
            style={{
              position: "fixed",
              bottom: "80px",
              right: "30px",
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            }}
          >
            <label
              for="about"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              Notes:
            </label>
            <div class="mt-2">
              <textarea
                id="about"
                name="about"
                rows="5" // Increase the height by increasing the number of rows
                class="block w-full pl-2 pr-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                style={{ width: "500px" }} // Increase the width by setting a larger width and add margin left and right
              ></textarea>
            </div>
          </div>
        )}
        
    </div>
  );
};
export default PerformanceDetailed;
