import React from 'react';
import {
    ArrowRightStartOnRectangleIcon
} from '@heroicons/react/20/solid'
import { useNavigate, useLocation } from 'react-router-dom';

const InterviewInstructions = () => {
    const location = useLocation();
	const email = location.state.email
    const trimmedQuestions = location.state.questions
    const navigate = useNavigate();
    const containerStyle = {
        marginTop: '20vh', // Adjust the margin-top value as needed
        marginLeft: '10vw',
        marginRight: '10vw',
    };

    const headingStyle = {
        textAlign: 'center',
        fontSize: '3em', // Adjust the size as needed
    };

    const textStyle = {
        textAlign: 'center',
    };
    const boldTextStyle = {
        fontWeight: 'bold',
    };

    const handleSubmit = () => {
        
            navigate(
                '/recordingfeedback', { state: { email: email, questions:trimmedQuestions } }
            );
    }


    return (
        <div style={containerStyle}>
            <h1 style={headingStyle}>Interview Instructions</h1>
            <p style={textStyle}>Welcome to the interview process! We're excited to learn more about you. Please follow the instructions below:</p>
            <div style={{ margin: '5vh 0' }}></div>

            <h2 style={boldTextStyle}>1. Preparation</h2>
            <p>
                Choose a quiet environment, and keep in mind that once you initiate the interview, 
                you cannot pause. Typically, the interview lasts 
                <span style={{ fontWeight: 'bold' }}> 20-30 minutes</span>, 
                so it's advisable to allocate that time without any interruptions.
            </p>
            <br />
            <h2 style={boldTextStyle}>2. Technical Requirements</h2>
            <p>Ensure you have a stable internet connection and a working microphone for the virtual interview.</p>
            <br />
            <h2 style={boldTextStyle}>3. Interview Process</h2>
            <p>
                During the interview, we'll give some questions based on the job description
                and what's in your CV. You'll have a total of   
                <span style={{ fontWeight: 'bold' }}> five minutes</span> per question 
                to read, think, and record your answer. 
                Each recording should be within <span style={{ fontWeight: 'bold' }}>two minutes</span>.
                You can retry the recording as many times as you want, and when you're 
                feeling good about one, select your choice and move on to the next.
                <br />
                <br />
                <span style={{ fontWeight: 'bold' }}>Reminder: </span> Once the time runs out 
                for your current question, the recording will stop automatically. You will still have 
                the chance to select the recording you want and proceed to the next question, 
                but you cannot start a new recording. 
                
            </p>
            
            <div style={{ margin: '5vh 0' }}></div>

            <p>We look forward to meeting you and wish you the best of luck!</p>
            <br />
            <br />

            <div className="flex justify-center mt-5">
                <button
                    type="button"
                    className={`inline-flex items-center rounded-md px-9 py-3 text-sm font-semibold shadow-sm bg-indigo-600 text-white hover:bg-indigo-500'
                        }`}
                    onClick={handleSubmit}
                >
                    <ArrowRightStartOnRectangleIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                    Start
                </button>
            </div>

        </div>
    );
}

export default InterviewInstructions;