import React, { useState, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
//import { useNavigate, useLocation } from "react-router-dom";
import RecordingPage from "./RecordingPage";
import "./RecordingFeedback.css"
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, addDoc, collection, setDoc, doc, updateDoc, getDoc, getDocs, query, where } from 'firebase/firestore';


const ThankYouPage = () => {
	return (
		<>
			<main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
				<div className="text-center">
					<p className="text-base font-semibold text-indigo-600">That was it!</p>
					<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Thank you for your time.</h1>
					<p className="mt-6 text-base leading-7 text-gray-600">Your will hear from us shortly.</p>
					<div className="mt-10 flex items-center justify-center gap-x-6">
						<a
							href="/landingpage"
							className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Go back home
						</a>
						<a href="/subscribenewsletter" className="text-sm font-semibold text-gray-900">
							Give us your feedback! <span aria-hidden="true">&rarr;</span>
						</a>
					</div>
				</div>
			</main>
		</>
	)
}


const RecordingFeedback = () => {
	const [qindex, setQindex] = useState(0);
	const db = getFirestore();
	const location = useLocation();
	const email = location.state.email
	const questions = location.state.questions
	useEffect(() => {
		if (qindex === 1) {
			const examcollref = doc(db, 'InterviewQA', email);
			updateDoc(examcollref, {
				"State": "Opened",
			})
			.then(response => {
				console.log("updated");
			})
			.catch(error => {
				console.log("hey", error.message);
			});
		}
	}, [qindex]);
	



		
	// console.log("email is ", email)
	// console.log("Questions: ", questions)
	// const questions = ["Tell us about your experience as a Family Law Expert?",
	// 	"Can you share your experience in handling cases involving divorce, child custody, and spousal support?",
	// 	"How do you manage client expectations, especially when outcomes may be uncertain or emotionally charged?"]
	return (



		<>
			{qindex !== questions.length ? (
				<RecordingPage
					onequestion={questions[qindex]}
					qindex={qindex}
					setQindex={setQindex}
					email={email}
				// jD={jD}
				// cv={cv}
				// bg={bg}
				// companyName={companyName}
				// language={language}
				/>
			) : (
				<>
					<ThankYouPage />
				</>
			)}
		</>

	);
};

export default RecordingFeedback;
