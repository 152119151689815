

import React, { useState, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./RecordingPage.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFirestore, addDoc, collection, setDoc, doc, updateDoc } from 'firebase/firestore';
import {
  ArrowRightIcon,
} from '@heroicons/react/20/solid'
const RecordingPage = ({
  onequestion,
  qindex,
  setQindex,
  email,
  jD,
  cv,
  bg,
  companyName,
  language,
}) => {
  /// Variables

  const windowHeight = window.innerHeight;
  const [isSelected, setIsSelected] = useState(-1);
  const [isRecording, setIsRecording] = useState(false);
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true });
  const [listAudios, setListAudios] = useState([]);
  const [isFeedbackTime, setIsFeedbackTime] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [responseWaiting, setResponseWaiting] = useState(false);
  const [transcription, setTranscription] = useState("");
  const db = getFirestore();
  const [totalCountdown, setTotalCountdown] = useState(300); // Initial countdown time in seconds (1 minute)
  const [totalCountdownOver, setTotalCountdownOver] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(120); // Recording countdowwn time in seconds
  const [timerRunning, setTimerRunning] = useState(false);
  const pageWidth = window.innerWidth;


  /// Functions

  // Initial countdown timer
  useEffect(() => {
    let totalCountdownTimer;

    if (totalCountdown > 0) {
      totalCountdownTimer = setInterval(() => {
        setTotalCountdown((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setTotalCountdownOver(true);
      if (isRecording) {
        toggleRecording()
      }
    }

    return () => clearInterval(totalCountdownTimer);
  }, [totalCountdown]);

  // Recording countdown timer
  useEffect(() => {
    let timer;

    if (timerRunning && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      // Stop recording when the timer reaches zero
      // stopRecording();
      // setTimerRunning(false);
      // setIsRecording(false);
      toggleRecording();
      // Additional logic or handling when the timer reaches zero
    }

    return () => clearInterval(timer);
  }, [timerRunning, timeRemaining]);

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
      setTimeRemaining(120)
      setTimerRunning(false);
    } else {
      startRecording();
      setTimerRunning(true);
    }
    setIsRecording(!isRecording);
  };
  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const notify = (msg) => {
    toast(msg);
  };
  //Handling of media URL
  const blobToBase64 = async (url) => {
    return new Promise(async (resolve, _) => {
      const blob = await fetch(url).then((r) => r.blob());

      // instantiate a file reader
      const fileReader = new FileReader();

      // read the file
      fileReader.readAsDataURL(blob);

      fileReader.onloadend = () => {
        resolve(fileReader.result); // Here is the base64 string
      };
    });
  };

  /// Hooks
  useEffect(() => {
    if (status === "stopped" && mediaBlobUrl) {
      setListAudios((prevList) => [...prevList, mediaBlobUrl]);
      //setBase64_(blobToBase64(mediaBlobUrl));
    }
  }, [status, mediaBlobUrl]);



  /// Return
  return (
    <div>
      <p style={{ fontSize: '1em', color: 'gray', marginTop: '5px', marginRight: '30px', textAlign: 'right' }}>
        Remaining time for this question: {Math.floor(totalCountdown / 60)}:{(totalCountdown % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}    
      </p>
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: '1em', color: 'gray', marginBottom: '5px', marginTop: '20vh' }}>Question {qindex + 1}</p>
        <div className="div3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '5px' }}>
          <h1 style={{ color: 'black', fontSize: '2em' }}>{onequestion}</h1>
          {totalCountdownOver && (
            <p style={{ fontSize: '1em', color: 'gray', marginTop: '5px' }}>
              Thank you for your time! Please select one recording to proceed to next!
            </p>
          )}
          {!totalCountdownOver && (
            <>
              <p style={{ fontSize: '1em', color: 'gray', marginTop: '5px' }}>
                Click the button to start recording.
              </p>

              <p style={{ fontSize: '1em', color: 'gray', textAlign: 'center' }}>
                Current recording time Remaining: {Math.floor(timeRemaining / 60)}:{(timeRemaining % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}
              </p>
            </>
          )}
        </div>
      </div>


      <div className={"div1"}>


        {/* Centered Recording Button */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: pageWidth / 2 - 40 }}>
          <button
            className={`record-button ${isRecording ? "recording" : ""}`}
            onClick={toggleRecording}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: totalCountdownOver ? 'grey' : '',
            }}
            disabled={totalCountdownOver}
          >
            <i className={`fa ${isRecording ? "fa-stop" : "fa-microphone"}`}></i>
          </button>
        </div>



        <div
          className={`inline-flex items-center rounded-xl px-9 py-3 text-sm font-semibold shadow-sm bg-indigo-600 text-white hover:bg-indigo-500
            }`}

          disabled={totalCountdownOver}

          onClick={() => {
            if (isSelected !== -1) {
              (async () => {
                const t = await blobToBase64(listAudios[isSelected]);
                setResponseWaiting(true);
                try {
                  const response = await axios.post(
                    "https://openai-hirex.onrender.com/lng_audio",
                    {
                      audio_data: t.replace("data:audio/wav;base64,", ""),
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        //"Access-Control-Allow-Origin": "*",
                        "mode": "no-cors"
                      },
                    }
                  );
                  setTranscription(response.data.text);
                  await delay(100);
                  console.log(response.data.text);

                  await updateDoc(doc(db, "InterviewQA", email), {
                    ['QA' + qindex]: "Question " + qindex + ":  " + onequestion + "  -  " + "Answer " + qindex + ":  " + response.data.text,
                    //['A' + qindex]: response.data.text,
                  });

                  await delay(5);

                  setFeedbackText("");
                  setListAudios([]);
                  setIsSelected(-1);
                  setTotalCountdown(300);
                  setQindex(qindex + 1);

                } catch (error) {
                  console.log("gg")
                  console.log(error);
                }

                setResponseWaiting(false);
                console.log(transcription);
                notify("Response saved!");
              })();
            } else {
              console.log("test");
              if (totalCountdownOver) {
                notify("Please select an audio first!");
              }
            }
          }
          }
        >
          Next
          <ArrowRightIcon className="ml-2  h-9 w-9" aria-hidden="true" />
        </div>
      </div>

      <div style={{ backgroundColor: "white" }}>
        <ul className="center-list">
          {listAudios.map((audioUrl, index) => (
            <li key={index}>
              <div
                className={`audioCard ${isSelected === index ? "selected" : ""
                  }`}
              >
                <div className={"rec"}>
                  <p>Recording {index + 1}:</p>
                </div>
                <div className={"rec8"}>
                  <audio controls>
                    <source src={audioUrl} type="audio/wav" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
                <div className={"rec8"}>
                  <button
                    className={`select-button`}
                    onClick={() => {
                      setIsSelected(index);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      size="xl"
                      style={{ color: isSelected === index ? "green" : "" }}
                    />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <ToastContainer />
        {responseWaiting && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>

    </div>
  );
};

export default RecordingPage;