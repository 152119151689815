import React, { useState, useEffect } from 'react';
import './PositionDetail.css';
import '../index.css';
import "../firebase";
import { useNavigate } from 'react-router-dom';
import { getFirestore, addDoc, collection, onSnapshot } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';


const CvMatchDetail = () => {
    const db = getFirestore();
    const location = useLocation();
    const navigate = useNavigate();
    const position_selected = location.state.extractedText
    return (
        <div className='container'>
            <p>{position_selected}</p>
        </div>
    )
}

export default CvMatchDetail