import React, { useState } from 'react';
import person from './Assets/person.png';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, addDoc, collection, setDoc, doc, updateDoc, getDoc, getDocs, query, where } from 'firebase/firestore';
import termsAndConditions from './terms';
import {
    ArrowRightStartOnRectangleIcon
} from '@heroicons/react/20/solid'
const TermsModal = ({ isOpen, onClose }) => {
    if (!isOpen) {
        return null;
    }


    return (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-4 rounded-md" style={{ maxWidth: '70vw', maxHeight: '80vh', overflowY: 'auto', whiteSpace: 'pre-line' }}>
                <p>{termsAndConditions}</p>
                <button onClick={onClose} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md">
                    Close
                </button>
            </div>
        </div>
    );
};

const EmailSubmission = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const db = getFirestore();
    const [responseWaiting, setResponseWaiting] = useState(false);
    const [isChecked, setIsChecked] = useState(false); // State for the checkbox
    const [termsModalOpen, setTermsModalOpen] = useState(false);

    const showTermsPopup = () => {
        setTermsModalOpen(true);
    };

    const closeTermsPopup = () => {
        setTermsModalOpen(false);
    };

    const handleInputChange = (event) => {
        setEmail(event.target.value);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Toggle the value of isChecked
    };
    const findEmailByIdentifier = async (id_arg) => (await db.collectionGroup('InterviewQA').where('Identifier', '==', id_arg).get()).docs.map(doc => doc.id);


    const handleSubmit = async () => {

        if (isChecked) {
            setResponseWaiting(true);
            const docRef = doc(db, "InterviewQA", email);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {

                if (docSnap.data().State === "Sent") {
                    
                    let positionValue, resumeValue, criteriasValue, jobdescValue, metricsValue;
                    const docData = docSnap.data();
                    positionValue = docData.Position;
                    resumeValue = docData.Resume
                    const positionsCollectionRef = collection(db, "Positions");
                    const querySnapshot = await getDocs(query(positionsCollectionRef, where("Position", "==", positionValue)));
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach((doc) => {

                            criteriasValue = doc.data().Criterias
                            jobdescValue = doc.data().JobDesc
                            metricsValue = doc.data().Metrics

                        });



                        try {
                            const response = await axios.post(
                                "https://openai-hirex.onrender.com/QGen",
                                {
                                    position: positionValue,
                                    jobDesc: jobdescValue,
                                    metrics: metricsValue,
                                    criteria: criteriasValue,
                                    resume: resumeValue
                                },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                       // "Access-Control-Allow-Origin": "*",
                                        "mode": "no-cors"
                                      },
                                }
                            );


                            const questions = response.data.text.split(/\d+-/).filter(question => question.trim() !== "");
                            const trimmedQuestions = questions.map(question => question.trim());

                            console.log(trimmedQuestions)
                            setResponseWaiting(false);
                            navigate(
                                '/interviewinstructions', { state: { email: email, questions: trimmedQuestions } }
                            );
                        } catch (error) {
                            setResponseWaiting(false);
                            console.log(error);
                            alert(error)
                        }



                    } else {
                        setResponseWaiting(false);
                        console.log("No documents found with the position value:", positionValue);
                        alert("No documents found with the position value:", positionValue);
                    }

                } else {
                    setResponseWaiting(false);
                    alert("ID has been previously used.");
                }

            } else {
                console.log("Document does not exist for email:", email);
                setResponseWaiting(false);
                alert("Wrong Identifier");
            }
        } else {
            alert("Please agree to the terms and conditions before going forward.");
        }
    };


    return (


        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>


            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="h-15 w-auto"
                    src="https://i.ibb.co/fSYCX6T/IrexAIT.png"
                    alt="Your Company"
                />
                <h2 className="mt-10 mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Welcome to Interview Lounge
                </h2>
            </div>

            <div className="input" style={{ alignItems: 'center' }}>
                <img src={person} alt='' />
                <input type='text' placeholder='Identifier' value={email} onChange={handleInputChange} />
            </div>

            <div>
                <div className="flex justify-center mt-5">
                    <button
                        type="button"
                        className={`inline-flex items-center rounded-md px-9 py-3 text-sm font-semibold shadow-sm ${isChecked ? 'bg-indigo-600 text-white hover:bg-indigo-500' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                            }`}
                        onClick={handleSubmit}
                        disabled={!isChecked && email.trim() != ''} // Disable the button if the checkbox is not checked
                    >
                        <ArrowRightStartOnRectangleIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        Start
                    </button>
                </div>

                <div className="text-lg mt-2 flex justify-center items-center">
                    <span>
                        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} className="mr-2 transform scale-80" />
                    </span>
                    <span>
                        I agree to the{' '}
                        <span className="text-blue-500" style={{ cursor: 'pointer' }} onClick={showTermsPopup}>
                            terms and conditions
                        </span>
                    </span>

                    {/* Render the custom modal component */}
                    <TermsModal isOpen={termsModalOpen} onClose={closeTermsPopup} />
                    {responseWaiting && (
                        <div className="loading-overlay">
                            <div className="loading-spinner"></div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default EmailSubmission;