import React, { useState, useEffect } from "react";
import axios from "axios";
import './Triage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faArrowUpFromBracket, faSearch, faGreaterThan, faMousePointer } from "@fortawesome/free-solid-svg-icons";
import { getFirestore, addDoc, collection, setDoc, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import check from './Assets/check.png'
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon } from '@heroicons/react/24/outline'
import {
  ArrowUpTrayIcon,
  CpuChipIcon,
  PaperAirplaneIcon,
  ArrowRightIcon,
  DocumentTextIcon,
  CursorArrowRippleIcon,
  XMarkIcon,
  HomeIcon,
  ChevronLeftIcon,
  UserIcon

} from '@heroicons/react/20/solid'
import 'tailwindcss/tailwind.css';


const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  // { name: 'Home', href: '/landingpage', current: true },
  { name: 'Dashboard', href: '/', current: true },

]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const Banner = ({ arg }) => {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-800 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 mt-4 mb-4 rounded-xl" >
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-200">
          <strong className="font-semibold">Step 1/2: Candidates' resumes are uploading</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Average waiting time is {Math.floor(arg * 0.60) + 1} minutes.
        </p>

      </div>
      <div className="flex flex-1 justify-end">

      </div>
    </div>

  );
}

const Banner2 = () => {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-800 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 mt-4 mb-4 rounded-xl" >
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 88.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 89.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 2%, 74.8% 0.9%)',
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-200">
          <strong className="font-semibold">🎉 Great! Files are now uploaded to our systems, please proceed to processing.</strong>
        </p>
      </div>
      <div className="flex flex-1 justify-end">
      </div>
    </div>
  );
}

const Banner3 = ({ arg }) => {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-800 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 mt-4 mb-4 rounded-xl" >
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-200">
          <strong className="font-semibold">Step 2/2: Candidates' resumes are being processed</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Average waiting time is {Math.floor(arg * 0.60) + 1} minutes.
        </p>

      </div>
      <div className="flex flex-1 justify-end">

      </div>
    </div>

  );
}

const Banner4 = ({ arg }) => {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-green-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 mt-4 mb-4 rounded-xl" >
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-200">
          <strong className="font-semibold">You are good to go!</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Please proceed to the Shortlist.
        </p>

      </div>
      <div className="flex flex-1 justify-end">

      </div>
    </div>

  );
}

const Triage = () => {
  const db = getFirestore();
  const storage = getStorage();
  const [extractedTexts, setExtractedTexts] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [acceptances, setAcceptances] = useState([]);
  const [triageAnalysis, setTriageAnalysis] = useState([]);
  const [loaders, setLoaders] = useState([]);
  // const inpFileRef = React.createRef();
  const location = useLocation();
  const navigate = useNavigate();
  const position = location.state.position
  const jd = location.state.jd
  const criterias = location.state.criterias
  const metrics = location.state.metrics
  const threshold_ = location.state.threshold
  const threshold = parseInt(threshold_);

  const [percentage, setPercentage] = useState(0);
  const [totalNumFiles, setTotalNumFiles] = useState(0);
  const [filteredExtractedTexts, setFilteredExtractedTexts] = useState([]);
  const [filteredFileNames, setFilteredFileNames] = useState([]);
  const [filteredAcceptances, setFilteredAcceptances] = useState([]);
  const [extractedEmails, setExtractedEmails] = useState([]);
  const [downsized, setDownsized] = useState(false)
  const [showBanner, setShowBanner] = useState(false)
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [processingStart, setProcessingStart] = useState(false);
  const [banner4, setBanner4] = useState(false);
  const [filteredExtractedEmails, setFilteredExtractedEmails] = useState([])


  /* useEffect(() => {
    console.log("Acceptances changed:", acceptances);
  }, [acceptances]);*/



  const extractEmail = (text) => {
    // Regular expression to match email addresses
    var emailRegex = /[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/gi;

    // Match email addresses in the text
    var emails = text.match(emailRegex);

    // Return the first email address found, if any
    if (emails && emails.length > 0) {
      return emails[0];
    } else {
      return 'No email address found';
    }
  }






  const handleUpload = async (event) => {
    event.target.classList.add('clicked2');
    setFileNames([])
    setAcceptances([])
    setExtractedTexts([])
    setPercentage(0)
    const inpFile = inpFileRef.current.files;
    setTotalNumFiles(inpFile.length)
    setShowBanner(true)
    for (let i = 0; i < inpFile.length; i++) {
      const formData = new FormData();
      formData.append("pdfFile", inpFile[i]);
      fetch("https://pdfreader-15w0.onrender.com/extract-text", {
        method: "post",
        body: formData,
      })
        .then((response) => response.text())
        //  .then((text) => setExtractedTexts());
        .then((text) => {
          setFileNames((name) => [...name, inpFile[i].name]);
          setExtractedTexts((prevTexts) => [...prevTexts, text.trim()]);
          var email = extractEmail(text.trim())
          setExtractedEmails((prevTexts) => [...prevTexts, email])
          const pdfRef = ref(storage, 'savedPDF/' + email);
          uploadBytes(pdfRef, inpFile[i])
        });


    }
    setTimeout(() => {
      setUploadCompleted(true);
    }, 10000);


  };

  const GetAccept = async (event) => {
    setAcceptances([])
    setProcessingStart(true)
    setUploadCompleted(false);
    event.target.classList.toggle('clicked2');
    for (let j = 0; j < extractedTexts.length; j++) {

      try {
        const triageResponse = await axios.post(
          "https://openai-hirex.onrender.com/triage",
          {
            position: position,
            criteria: criterias,
            resume: extractedTexts[j] //+ ": BE EXTREMELY FAST TO RESPOND, no more than 3 seconds.",
          },
          {
            headers: {
              "Content-Type": "application/json",
              // "Access-Control-Allow-Origin": "*",
              "mode": "no-cors"
            },
          }
        );

        console.log("Triage Analysis:", triageResponse.data.text);
        const criteriaText = triageResponse.data.text;

        // Extract scores from the criteria text
        const scores = criteriaText.match(/Score: (\d)/g).map(match => parseInt(match.match(/\d/)[0]));

        // Calculate the sum of scores
        const sum = scores.reduce((total, score) => total + score, 0);

        // Calculate the average
        const average = (sum / (3 * scores.length)) * 100;

        console.log("Sum of scores:", sum);
        console.log("Average score:", Math.floor(average));

        setTriageAnalysis(prevTriageAnalysis => [...prevTriageAnalysis, triageResponse.data.text]);
        setAcceptances(prevAcceptances => [...prevAcceptances, Math.floor(average)]);

      } catch (error) {
        setTriageAnalysis(prevTriageAnalysis => [...prevTriageAnalysis, -2]);
        setAcceptances(prevAcceptances => [...prevAcceptances, -2]);
      }






    }
    setTimeout(() => {
      //  setProcessingStart(false);
      setBanner4(true)
    }, 10000);

  };



  const CandidateItem = ({ index }) => {
    return (
      <li key={index} className="flex justify-between gap-x-6 py-5">

        <div className="flex min-w-0 gap-x-4 items-center">
          <DocumentTextIcon className="h-7 w-7 flex-none rounded-full" />
          <div className="min-w-0 flex-auto flex items-center justify-center">
            <p className="text-l font-semibold leading-6 text-gray-900"> {fileNames[index]}</p>

          </div>
        </div>


        <div className="flex">
          {(extractedEmails[index]) && (
            <div className="flex items-center justify-center flex-col mr-9">
              <p className="text-xs font-semibold leading-6 text-gray-900  ">{extractedEmails[index]}</p>
            </div>
          )}
          {(extractedTexts[index] && (acceptances[index] + 1)) ? (
            <>
              <div className="flex items-center justify-center flex-col mr-9">
                <p className="text-l font-bold leading-6 text-gray-900">{acceptances[index]}%</p>
                <p className="text-xs font-semibold leading-6 text-gray-900  w-600">Resume match</p>
              </div>
            </>
          ) : (processingStart ? (<>
            <div className="flex items-center justify-center flex-col mr-9">
              <div className="spinner">
                <div className="spinner-icon"></div>%
              </div>
              <p className="text-xs font-semibold leading-6 text-gray-900  w-600">Resume match</p>
            </div>
          </>) : (<div className="flex items-center justify-center flex-col mr-9 w-600">


          </div>))}


          <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end justify-center">
            {/* <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                navigate(
                  '/cv-match-detail',
                  { state: { extractedText: extractedTexts[index] } }
                  // also will send detailed response
                );
              }}
            >
              Details
              <ArrowRightIcon className="ml-2 mr-0.5 h-5 w-5" aria-hidden="true" />
            </button> */}
          </div>
        </div>

      </li>
    );
  };


  // const [progressValue, setProgressValue] = useState(100); Initialize progress bar with a default value
  const inpFileRef = React.useRef(null);
  const handleButtonClick = (event) => {
    event.target.classList.toggle('clicked2');
    inpFileRef.current.click();
  };
  return (
    <div className="relative isolate min-h-full">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-7 "
                      src="https://i.ibb.co/GnW0bz3/ico-white.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'flex items-center rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <HomeIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <button
                      type="button"
                      className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">{user.name}</div>
                    <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 ">
        <header className="bg-white shadow flex justify-between items-center px-4 py-6">
          <div className="flex items-center space-x-2">
            <div onClick={() => { navigate(-1) }}>
              <ChevronLeftIcon className="h-9 w-9 cursor-pointer" aria-hidden="true" />
            </div>
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Triage: {position}</h1>
          </div>
          <div className="flex space-x-2">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-gray-300 px-9 py-3 text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                navigate("/performance", { state: { position: position } }
                );
              }}
            >
              Candidates
              <UserIcon className="h-5 w-5 ml-5" aria-hidden="true" />
            </button>
            <button
              disabled={!(banner4 && processingStart)}
              type="button"
              className={`inline-flex items-center rounded-md px-9 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${(banner4 && processingStart) ? 'bg-indigo-600 text-white hover:bg-indigo-500' : 'bg-gray-300 text-gray-500 hover:bg-gray-500 cursor-not-allowed'
                }`}
              onClick={
                () => {
                  const filteredItems = [];
                  for (let i = 0; i < acceptances.length; i++) {
                    if (acceptances[i] > threshold && extractedEmails[i] !== "No email address found") {
                      console.log("inside if")
                      filteredItems.push({
                        extractedText: extractedTexts[i],
                        fileName: fileNames[i],
                        acceptance: acceptances[i],
                        triageDetails: triageAnalysis[i],
                        extractedEmail: extractedEmails[i]
                      });
                    }
                  }
                  const sortedItems = filteredItems.sort((a, b) => b.acceptance - a.acceptance);
                  console.log("filteredItems sent", filteredItems)

                  navigate('/shortlist', { state: { filteredItems: filteredItems, position: position } })

                }
              }
            >
              Shortlist
              <PaperAirplaneIcon className="h-5 w-5 ml-5" aria-hidden="true" />
            </button>
          </div>
        </header>
      </div>




      <main>

        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="mt-5 flex lg:ml-4 lg:mt-0 justify-center">
            <span className="hidden sm:flex flex-row">
              <input type="file" id="inpFile" ref={inpFileRef} multiple style={{ display: 'none' }} />
              <button
                type="button"
                className="equal-width-button inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={handleButtonClick}
              >
                <CursorArrowRippleIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" style={{ pointerEvents: "none" }} />
                Choose resumes [.pdf]
              </button>
            </span>

            <span className="ml-3 hidden sm:flex flex-row">
              <button
                type="button"
                className="equal-width-button inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={handleUpload}
              >
                <ArrowUpTrayIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" style={{ pointerEvents: "none" }} />
                Upload
              </button>
            </span>

            <span className="ml-3 hidden sm:flex flex-row">
              <button
                type="button"
                // Disable the second button until the first button action is completed
                disabled={!uploadCompleted}
                className={`equal-width-button inline-flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 ${uploadCompleted ? 'bg-green-500 hover:bg-green-600' : 'bg-red-300 cursor-not-allowed'}`}
                onClick={GetAccept}
              >
                <CpuChipIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400 text-white" aria-hidden="true" style={{ pointerEvents: "none" }} />
                {uploadCompleted ? 'Start Processing' : (!processingStart ? 'Upload Required' : (banner4 ? "Processing complete!" : "Processing..."))}
              </button>
            </span>





          </div>


          {(showBanner && !uploadCompleted && !banner4 && !processingStart) && <Banner arg={totalNumFiles} />}
          {(uploadCompleted && !processingStart) && <Banner2 />}
          {(processingStart && !banner4) && <Banner3 arg={totalNumFiles} />}
          {(banner4 && processingStart) && <Banner4 />}


          {extractedTexts.length !== 0 && (
            <>
              {
                extractedTexts.map((text, index) => (
                  <ul className="divide-y divide-gray-100">
                    <CandidateItem key={index} index={index} />
                  </ul>
                ))
              }</>
          )
          }


        </div>
      </main>
    </div>

  );
};

export default Triage;