const termsAndConditions = `
1. Introduction:

Welcome to HireX.ai (the "Website"). By accessing and using this Website, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use this Website.


2. User Responsibilities:

- You must be 18 years or older to use this Website.
- You are responsible for maintaining the confidentiality of your account and password.
- You agree not to engage in any prohibited activities, including:
    a. Unauthorized Access: Users must not attempt to access accounts, systems, or data without proper authorization.
    b. Malicious Activities: Engaging in hacking, cracking, or distributing malware is strictly prohibited.
    c. Spamming: Sending unsolicited messages, advertisements, or any form of spam is not allowed.
    d. Abuse of Resources: Users must not intentionally overload or abuse the website's resources, including bandwidth or server capacity.
    e. Impersonation: Impersonating another person or entity, including using false account information, is prohibited.
    f. Illegal Content: Uploading, sharing, or promoting any illegal, harmful, or rights-violating content is strictly forbidden.
    g. Harassment and Threats: Engaging in harassment, bullying, or making threats towards other users is not allowed.
    h. Intellectual Property Violations: Users must not infringe on the intellectual property rights of others, including copyright and trademark violations.
    i. Fraudulent Activities: Participating in fraudulent or deceptive activities, such as phishing or identity theft, is strictly prohibited.
    j. Violence and Hate Speech: Promoting violence, discrimination, or hate speech based on protected characteristics is not allowed.
    k. Gambling: Engaging in any form of online gambling or promoting gambling activities is prohibited.
    l. Violation of Laws: Users must not violate any applicable local, national, or international laws or regulations.
    m. Unauthorized Commercial Activities: Conducting commercial activities without proper authorization or in violation of the website's terms is not allowed.
    n. Misuse of User Data: Unauthorized collection, use, or sharing of personal information of users is strictly prohibited.
    o. Violation of Terms and Conditions: Users must not breach any other terms and conditions outlined by the website.


3. Intellectual Property:

- The content on this Website, including text, graphics, logos, and images, is the property of HireXai and is protected by copyright laws.
- You may not use, reproduce, or distribute the content without explicit permission.

4. Privacy Policy:

- Our privacy practices are outlined in our Privacy Policy [provide a link].
- By using this Website, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.

5. Data Storage:

- Your email and voice data will be stored for a period of 3 months.

6. Termination and Suspension:

- We reserve the right to terminate or suspend your account for any reason, without notice.
- You may terminate your account at any time by [provide termination instructions].

7. Liability and Warranty:

- This Website is provided "as is" without any warranties, expressed or implied.
- We shall not be liable for any indirect, incidental, special, or consequential damages.

8. Changes to Terms and Conditions:

- We reserve the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting on the Website.

9. Miscellaneous:

- If any provision of these terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.
- These terms constitute the entire agreement between you and HireX.ai regarding the use of the Website.

By using this Website, you acknowledge that you have read, understood, and agree to these terms and conditions.
`;

export default termsAndConditions;
