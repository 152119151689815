const people = [
  {
    name: 'Amine SDOUR',
    role: 'Founder / CEO',
    imageUrl:
      'https://media.licdn.com/dms/image/D4E03AQED3DBNLcQh5Q/profile-displayphoto-shrink_800_800/0/1687659339050?e=1712793600&v=beta&t=UetrYQnth7Fkcy4caDUZbyHLLmqbGXljReHjRyEkn1g',
  },
  {
    name: 'Alex MOFFAT',
    role: 'Co-Founder / CFO',
    imageUrl:
      'https://media.licdn.com/dms/image/D4D03AQHptrmdMQJnHg/profile-displayphoto-shrink_800_800/0/1705586788447?e=2147483647&v=beta&t=O42eurLqCX42ixMXMFP-rivcj9mBkS9zZkI_-UKu7t8',
  },
  {
    name: 'Andreas BERGSTROM',
    role: 'Co-Founder / CTO',
    imageUrl:
      'https://media.licdn.com/dms/image/C4E03AQFJj4qZlwjSjA/profile-displayphoto-shrink_800_800/0/1635252562070?e=2147483647&v=beta&t=QGIjXct5ki4pVyn7-nZpzQvMcfXzPzb0FZ259cMn_gU',
  },
  {
    name: 'Zehao JIANG',
    role: 'Co-Founder / COO',
    imageUrl:
      'https://media.licdn.com/dms/image/D4D03AQF33o5fG0F6Ww/profile-displayphoto-shrink_800_800/0/1685031636119?e=2147483647&v=beta&t=OMzJyq6ldzy0WHQoJC5pvRDBxYFxakgm50itYuFtY64',
  }
  // More people...
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">

            Meet the leadership behind HireXai, revolutionizing the recruitment landscape. Our team is driven by a passion for innovation and a commitment to transforming traditional hiring processes.
          </p>
        </div>
        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
          {people.map((person) => (
            <li key={person.name}>
              <div className="flex items-center gap-x-6">
                <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
