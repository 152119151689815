// Dashboard.js

import React, { useState, useEffect } from "react";
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import "../firebase";
import axios from "axios";
import { Fragment } from 'react'
import { collection, onSnapshot, setDoc, getDoc, doc, getFirestore, updateDoc, getDocs } from "firebase/firestore";
import {
  ArrowUpTrayIcon,
  CpuChipIcon,
  PaperAirplaneIcon,
  ArrowRightIcon,
  DocumentTextIcon,
  CursorArrowRippleIcon,
  XMarkIcon,
  HomeIcon,
  ChevronLeftIcon,
  UserIcon

} from '@heroicons/react/20/solid'
import 'tailwindcss/tailwind.css';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon } from '@heroicons/react/24/outline'
import formData from "form-data";
import Mailgun from "mailgun.js";
const mailgun = new Mailgun(formData);
const mg = mailgun.client({
  username: "api",
  // key: "a8d18350511fa06bf5f00008a9e02ff9-8c8e5529-d58a6f34",
  key: "ce725b40348e7ef52ee638cb13ed577e-b7b36bc2-f5d52fad",
  url:"https://api.eu.mailgun.net"
});


function generateRandomId() {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function ShortList() {
  const [emails, setEmails] = useState("");
  const db = getFirestore()
  const navigate = useNavigate();
  const location = useLocation();
  const position = location.state.position
  const filteredItems = location.state.filteredItems
  console.log("filteredItems received", filteredItems)

  // Generate random IDs
  const randomIds = Array.from({ length: filteredItems.length }, () => generateRandomId());

  // const [numOfPositions, setNumOfPositions] = useState(filteredItems.length)
  // const [processingStart, setProcessingStart] = useState(false);
  // const [banner4, setBanner4] = useState(false);
  const user = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }
  const navigation = [
    // { name: 'Home', href: '/landingpage', current: true },
    { name: 'Dashboard', href: '/', current: true },

  ]
  const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
  ]
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const Banner5 = () => {
    return (
      <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-green-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 mt-4 mb-4 rounded-xl" >
        <div
          className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div
          className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
          <p className="text-sm leading-6 text-gray-200">
            <strong className="font-semibold">Applicants are now shortlisted!</strong>
            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
              <circle cx={1} cy={1} r={1} />
            </svg>
            Click on "Notify Candidates" for us to send them invitations to our Interview Lounges.
          </p>

        </div>
        <div className="flex flex-1 justify-end">

        </div>
      </div>

    );
  }
  const CandidateItemFiletered = ({ index, item }) => {
    return (
      <li key={index} className="flex justify-between gap-x-6 py-5">

        <div className="flex min-w-0 gap-x-4 items-center">
          <DocumentTextIcon className="h-7 w-7 flex-none rounded-full" />
          <div className="min-w-0 flex-auto flex items-center justify-center">
            <p className="text-l font-semibold leading-6 text-gray-900"> {item.fileName}</p>

          </div>
        </div>


        <div className="flex">

          <div className="flex items-center justify-center flex-col mr-9">
            <p className="text-xs font-semibold leading-6 text-gray-900  ">{item.extractedEmail}</p>
          </div>

          <div className="flex items-center justify-center flex-col mr-9">
            <p className="text-l font-bold leading-6 text-gray-900">{item.acceptance}%</p>
            <p className="text-xs font-semibold leading-6 text-gray-900  w-600">Resume match</p>
          </div>



          <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end justify-center">
            {/* <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                navigate(
                  '/cv-match-detail',
                  { state: { extractedText: item.extractedText } }
                  // also will send detailed response
                );
              }}
            >
              Details
              <ArrowRightIcon className="ml-2 mr-0.5 h-5 w-5" aria-hidden="true" />
            </button> */}
          </div>
        </div>

      </li>
    );
  };

  const [dataLoaded, setDataLoaded] = useState(false); // State to track if data is loaded

  useEffect(() => {
    const fetchData = async () => {
      for (let j = 0; j < filteredItems.length; j++) {

        await setDoc(doc(db, "InterviewQA", randomIds[j]), {
          "placeholder": filteredItems[j].extractedEmail,
          "Resume": filteredItems[j].extractedText,
          "Position": position,
          "ResumeName": filteredItems[j].fileName,
          "TriageScore": filteredItems[j].acceptance,
          "TriageDetails": filteredItems[j].triageDetails,
          "Identifier": randomIds[j],
          "State": "default"
        });
      }
      setDataLoaded(true); // Update state to indicate data is loaded
    };
    if (!dataLoaded) {
      fetchData();
    }
    // Call the async function immediately

    // Cleanup function (if needed)
    return () => {
      // Cleanup code if needed
    };
  }, []);


  return (
    <div className="relative isolate min-h-full">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-7 "
                      src="https://i.ibb.co/GnW0bz3/ico-white.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'flex items-center rounded-md px-3 py-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <HomeIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <button
                      type="button"
                      className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">{user.name}</div>
                    <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 ">
        <header className="bg-white shadow flex justify-between items-center px-4 py-6">
          <div className="flex items-center space-x-2">
            <div onClick={() => { navigate(-1) }}>
              <ChevronLeftIcon className="h-9 w-9 cursor-pointer" aria-hidden="true" />
            </div>
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Shortlist: {position}</h1>
          </div>
          <div className="flex space-x-2">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-gray-300 px-9 py-3 text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                navigate("/performance", { state: { position: position } }
                );
              }}
            >
              Candidates
              <UserIcon className="h-5 w-5 ml-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={`inline-flex items-center rounded-md px-9 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600  bg-indigo-600 text-white hover:bg-indigo-500`}
              onClick={async () => {
                // const twoemails = ["zehao323@gmail.com", "sdouramine@gmail.com"]
                // const identifiers = ["1234", "5678"]
                for (let j = 0; j < filteredItems.length; j++) {
                  const querySnapshot = await getDocs(collection(db, 'InterviewQA'));
                  for (const doc_ of querySnapshot.docs) {
                    const data = doc_.data();
                    if (data.placeholder === filteredItems[j].extractedEmail) {
                      try {
                        await mg.messages.create('mg.hirex-ai.com', {
                          from: "HireX-ai <no-reply@mg.hirex-ai.com>",
                          to: [filteredItems[j].extractedEmail],
                          subject: "Invites you to the interview", 
                          template: "interview invite",
                          'v:jobPosition': position,
                          'v:identifier': data.Identifier,

                        });

                        const examcollref = doc(db, 'InterviewQA', data.Identifier)
                        await updateDoc(examcollref, {
                          "State": "Sent",
                        }).then(response => {
                          console.log("updated")
                        }).catch(error => {
                          console.log("hey", error.message)
                        })




                        console.log("Document successfully updated!");
                      } catch (error) {
                        console.error("Error sending email or updating document: ", error);
                      }
                    }
                  }
                }

                alert("Emails has been successfully sent to candidates!");

              }
              }
            >
              Notify Candidates
              < PaperAirplaneIcon className="h-5 w-5 ml-5" aria-hidden="true" />
            </button>
          </div>
        </header>
      </div >




      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">

          {filteredItems.length > 0 ? (
            <>
              <Banner5 />
              <ul role="list" className="divide-y divide-gray-100">
                {filteredItems.map((item, index) => (
                  <CandidateItemFiletered key={index} item={item} />
                ))}

              </ul>
            </>
          ) :
            (<>
              <main className="grid min-h-full place-items-center   px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                  <p className="text-base font-semibold text-red-600">Unfortunately, no candidates were shortlisted, please choose a less restrictive threshold for this position, and ensure resumes include email address of the candidate, as those without may be filtered due to contactability concerns.</p>
                </div>
              </main>
            </>)
          }

        </div >
      </main>
    </div >


  );
}

export default ShortList;
