import React, { useState, Fragment } from "react";
import "../index.css";
import "../firebase";
import "./PositionSubmission.css";
import { useNavigate } from "react-router-dom";
import { getFirestore, addDoc, collection } from "firebase/firestore";
//import Alert from '@mui/material/Alert';

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import {
  PencilSquareIcon,
  PlusCircleIcon,
  HomeIcon,
  ChevronLeftIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
  // { name: 'Home', href: '/landingpage', current: true },
  { name: "Dashboard", href: "/", current: true },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PositionSubmission() {
  const db = getFirestore();
  const navigate = useNavigate();

  const [position, setPosition] = useState("");
  const handleInputPosition = (event) => {
    setPosition(event.target.value);
  };

  const [jd, setJd] = useState("");
  const handleInputJd = (event) => {
    setJd(event.target.value);
  };

  const [criterias, setCriterias] = useState("");

  const [priority, setPriority] = useState("");
  const [threshold, setThreshold] = useState("");
  const handleInputThreshold = (event) => {
    setThreshold(event.target.value);
  };

  const saveDataToFirestore = async (c, j, m, t, p) => {
    if (!t) {
      alert("No threshold value selected");
      return;
    }
    try {
      const docRef = await addDoc(collection(db, "Positions"), {
        Criterias: c,
        JobDesc: j,
        Metrics: m,
        Threshold: t,
        Position: p,
        State: "On",
      });

      alert("Position saved successfully!");
      navigate("/");
    } catch (error) {
      alert("Position not saved, try again!");
    }
  };

  const [criteriaList, setCriteriaList] = useState([
    { priority: "", criteria: "" },
  ]);

  const handleInputPriority = (index, value) => {
    const newList = [...criteriaList];
    newList[index].priority = value;
    setCriteriaList(newList);
  };

  const handleInputCriterias = (index, value) => {
    const newList = [...criteriaList];
    newList[index].criteria = value;
    setCriteriaList(newList);
  };

  const addCriteriaField = () => {
    setCriteriaList([...criteriaList, { priority: "", criteria: "" }]);
  };

  const generateCriteriaText = () => {
    let text = "Criterias Bulletpoints :\n";
    criteriaList.forEach((item, index) => {
      text += `${index + 1}/ ${item.priority}: ${item.criteria}\n`;
    });
    return text;
  };

  const [metricsList, setMetricsList] = useState([
    { priority: "", metric: "" },
  ]);

  const handleInputPriorityMetrics = (index, value) => {
    const newList = [...metricsList];
    newList[index].priority = value;
    setMetricsList(newList);
  };

  const handleInputMetrics = (index, value) => {
    const newList = [...metricsList];
    newList[index].metric = value;
    setMetricsList(newList);
  };

  const addMetricsField = () => {
    setMetricsList([...metricsList, { priority: "", criteria: "" }]);
  };

  const generateMetricsText = () => {
    let text = "Metrics Bulletpoints :\n";
    metricsList.forEach((item, index) => {
      text += `${index + 1}/ ${item.priority}: ${item.metric}\n`;
    });
    return text;
  };

  const criteriasParagraph = generateCriteriaText();
  const metricsParagraph = generateMetricsText();

  return (
    <div className="relative isolate min-h-full">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>

      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-7 "
                      src="https://i.ibb.co/GnW0bz3/ico-white.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "flex items-center rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <HomeIcon
                            className="h-5 w-5 mr-2"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <button
                      type="button"
                      className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.imageUrl}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium leading-none text-gray-400">
                      {user.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 ">
        <header className="bg-white shadow flex justify-between items-center px-4 py-6">
          <div className="flex items-center space-x-2">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon
                className="h-9 w-9 cursor-pointer"
                aria-hidden="true"
              />
            </div>
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Add new position
            </h1>
          </div>

          <div className="flex space-x-2">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-9 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                saveDataToFirestore(
                  criteriasParagraph,
                  jd,
                  metricsParagraph,
                  threshold,
                  position
                );
              }}
            >
              Publish
              <PencilSquareIcon className="h-5 w-5 ml-5" aria-hidden="true" />
            </button>
          </div>
        </header>
      </div>

      <main>
        <form>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <div className="border-b border-gray-900/10 pb-12">
              {/* <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p> */}

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Position title
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="Position"
                      id="Position"
                      //required
                      className="block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Enter Position"
                      value={position}
                      onChange={handleInputPosition}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <div className="flex items-center">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Acceptance threshold
                    </label>
                    <span className="info-icon ml-4">
                      <InformationCircleIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                      <span className="tooltip">
                        Minimum matching level between the Resume and Job
                        posting.
                      </span>
                    </span>
                  </div>
                  <div className="mt-2">
                    <select
                      value={threshold}
                      onChange={handleInputThreshold}
                      className="block w-full rounded-md border-0 py-2 pl-2 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option disabled value="">
                        {" "}
                        Select Threshold
                      </option>
                      <option value="10">10%</option>
                      <option value="30">30%</option>
                      <option value="50">50%</option>
                      <option value="70">70%</option>
                    </select>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Job Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      type="text"
                      name="street-address"
                      rows="4"
                      id="street-address"
                      autoComplete="street-address"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={jd}
                      onChange={handleInputJd}
                      placeholder="Enter job description"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <div className="flex items-center">
                    <p className="mr-4">Resume screening criterias</p>
                    <span className="info-icon">
                      <InformationCircleIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                      <span className="tooltip">
                        Hard skills for CV assessment. E.g. master degree in
                        math, experiences with copywriting, etc.
                      </span>
                    </span>
                    <div className="ml-9 flex-grow border-b border-gray-900/10 mt-2"></div>
                  </div>
                  <div className="col-span-full">
                    {criteriaList.map((criteria, index) => (
                      <div key={index} className="col-span-full">
                        <label
                          htmlFor={`street-address-${index}`}
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Criteria {index + 1}
                        </label>

                        <div className="mt-2 flex items-center">
                          <div className="mr-4">
                            <select
                              value={criteria.priority}
                              onChange={(e) =>
                                handleInputPriority(index, e.target.value)
                              }
                              className="block w-full rounded-md border-0 py-2 pl-2 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option disabled value="">
                                Priority
                              </option>
                              <option value="Required">Required</option>
                              <option value="Recommended">Recommended</option>
                              <option value="Nice-to-have">Nice-to-have</option>
                            </select>
                          </div>
                          <input
                            type="text"
                            name={`street-address-${index}`}
                            id={`street-address-${index}`}
                            autoComplete="street-address"
                            className="block flex-grow rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={criteria.criteria}
                            onChange={(e) =>
                              handleInputCriterias(index, e.target.value)
                            }
                            placeholder="Enter criteria"
                          />
                          {index === criteriaList.length - 1 ? (
                            <button onClick={addCriteriaField}>
                              <PlusCircleIcon
                                className="h-9 w-9 text-indigo-600 ml-4"
                                aria-hidden="true"
                              />
                            </button>
                          ) : (
                            <div
                              className="h-9 w-9 text-indigo-600 ml-4"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <div className="flex items-center">
                    <p className="mr-4">Interview performance metrics</p>
                    <span className="info-icon">
                      <InformationCircleIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                      <span className="tooltip">
                        Soft skills for interview performance assessment. E.g.
                        take initiatives, show good teamworking skills, etc.
                      </span>
                    </span>
                    <div className="ml-9 flex-grow border-b border-gray-900/10 mt-2"></div>
                  </div>

                  <div className="col-span-full">
                    {metricsList.map((metric, index) => (
                      <div key={index} className="col-span-full">
                        <label
                          htmlFor={`street-address-${index}`}
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Metric {index + 1}
                        </label>
                        <div className="mt-2 flex items-center">
                          <div className="mr-4">
                            <select
                              value={metric.priority}
                              onChange={(e) =>
                                handleInputPriorityMetrics(
                                  index,
                                  e.target.value
                                )
                              }
                              className="block w-full rounded-md border-0 py-2 pl-2 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option disabled value="">
                                Priority
                              </option>
                              <option value="Required">Required</option>
                              <option value="Recommended">Recommended</option>
                              <option value="Nice-to-have">Nice-to-have</option>
                            </select>
                          </div>
                          <input
                            type="text"
                            name={`street-address-${index}`}
                            id={`street-address-${index}`}
                            autoComplete="street-address"
                            className="block flex-grow rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={metric.criteria}
                            onChange={(e) =>
                              handleInputMetrics(index, e.target.value)
                            }
                            placeholder="Enter metric"
                          />
                          {index === metricsList.length - 1 ? (
                            <button onClick={addMetricsField}>
                              <PlusCircleIcon
                                className="h-9 w-9 text-indigo-600 ml-4"
                                aria-hidden="true"
                              />
                            </button>
                          ) : (
                            <div
                              className="h-9 w-9 text-indigo-600 ml-4"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
}
