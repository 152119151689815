// Dashboard.js

import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "../firebase";
import axios from "axios";
import {
  collection,
  onSnapshot,
  setDoc,
  getDoc,
  doc,
  getFirestore,
} from "firebase/firestore";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ArrowPathIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  ClockIcon,
  HomeIcon,
  ChevronLeftIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import { connectStorageEmulator } from "firebase/storage";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [{ name: "Dashboard", href: "/", current: true }];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function PerformanceDashboard() {
  const [numOfPositions, setNumOfPositions] = useState("");
  const [emails, setEmails] = useState([]);
  const db = getFirestore();
  const navigate = useNavigate();
  const [feedbackData, setFeedbackData] = useState({});
  const [interviewQAData, setInterviewQAData] = useState({});
  const location = useLocation();
  const [globalList, setGlobalList] = useState([]);
  const [dictInfo, setDictInfo] = useState([]);
  const position = location.state.position;
  const jd = location.state.jd;
  const criterias = location.state.criterias;
  const metrics = location.state.metrics;
  const threshold = location.state.threshold;
  let num;

  const getFeedback = async (prompt) => {
    try {
      const response = await axios.post(
        "https://hirexweb.onrender.com/full_interview",
        {
          prompt__: prompt,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            mode: "no-cors",
          },
        }
      );

      return response.data.text;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Feedback"), (snapshot) => {
      const feedbackData = {};
      snapshot.docs.forEach((doc) => {
        feedbackData[doc.id] = doc.data();
      });
      setFeedbackData(feedbackData);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // const unsubscribe = onSnapshot(collection(db, "InterviewQA"), (snapshot) => {
    //   num = snapshot.docs.length;
    //   setNumOfPositions(num);

    //   const names = snapshot.docs.map((doc) => doc.data()["placeholder"]);
    //   setEmails(names);

    const unsubscribe = onSnapshot(
      collection(db, "InterviewQA"),
      (snapshot) => {
        //const emails = snapshot.docs.map((doc) => doc.data()["placeholder"]);
        // const emails = snapshot.docs
        //   .filter(doc => doc.data()["Position"] === position)
        //   .map(doc => doc.data()["placeholder"]);

        // let num = emails.length;
        // setNumOfPositions(num);
        // setEmails(emails);

        // const data = snapshot.docs
        //   .filter(doc => doc.data()["Position"] === position)
        //   .map(doc => {
        //     const { placeholder, TriageScore, ResumeName, Resume, State, Identifier, TriageDetails } = doc.data();
        //     return { placeholder, TriageScore, ResumeName, Resume, State, Identifier, TriageDetails };
        //   });
        // setDictInfo(data)
        const data = snapshot.docs
          .filter((doc) => doc.data()["Position"] === position)
          .map((doc) => {
            const docData = doc.data();

            return { ...docData };
          });
        setDictInfo(data);

        const emails = data.map((item) => item.placeholder);
        let num = emails.length;
        setNumOfPositions(num);
        setEmails(emails);

        // Iterate through emails
        emails.forEach(async (email) => {
          // Check if a document with the same email exists in the Feedback collection
          const feedbackDoc = await getDoc(doc(db, "Feedback", email));
          if (feedbackDoc.exists()) {
            // If a document with the same email exists in Feedback collection, skip to the next email
            console.log(email);
            console.log("feedbackDoc exists", feedbackDoc);
            return;
          }

          // If Feedback collection doesn't contain a document with the same email name,
          // check if the InterviewQA document contains "Q0" and "A0"
          const interviewQADoc = snapshot.docs.find(
            (doc) => doc.data()["placeholder"] === email
          );
          if (!interviewQADoc) return; // Safety check

          const interviewQAData = interviewQADoc.data();
          setInterviewQAData(interviewQAData);
          if (interviewQAData["QA0"]) {
            setGlobalList((prevGlobalList) => [...prevGlobalList, email]);
            // If InterviewQA document contains "Q0" and "A0", retrieve all fields and store them in a variable
            const interviewQAFields = { ...interviewQAData }; // Copy all fields
            delete interviewQAFields["placeholder"]; // Remove placeholder field
            delete interviewQAFields["Position"];
            delete interviewQAFields["Resume"];
            delete interviewQAFields["TriageScore"];
            delete interviewQAFields["ResumeName"];
            delete interviewQAFields["Identifier"];
            delete interviewQAFields["TriageDetails"];
            delete interviewQAFields["State"];

            let interviewQAFieldsString = "";
            Object.keys(interviewQAFields).forEach((key) => {
              interviewQAFieldsString += interviewQAFields[key] + "\n"; // Append the value of each field
            });

            // Do whatever you want with interviewQAFields here

            // console.log(email)
            // console.log("interviewQAFields", interviewQAFieldsString)

            try {
              const response = await axios.post(
                "https://openai-hirex.onrender.com/InterviewScoring",
                {
                  position: position,
                  metrics: metrics,
                  interviewQAFieldsString: interviewQAFieldsString,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    // "Access-Control-Allow-Origin": "*",
                    mode: "no-cors",
                  },
                }
              );

              console.log(response.data.text);
              const feedback_detailed = response.data.text;
              try {
                const response1 = await axios.post(
                  "https://openai-hirex.onrender.com/full_interview",
                  {
                    interview_scores: feedback_detailed,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      //  "Access-Control-Allow-Origin": "*",
                      mode: "no-cors",
                    },
                  }
                );

                console.log(response1.data.text);

                const regex = /Final percentage: (\d+)%/;

                // Extract the final percentage using match
                const feedback_score = response1.data.text.match(regex);

                // Check if there's a match and extract the percentage
                if (feedback_score && feedback_score[1]) {
                  const finalPercentage = feedback_score[1]; // Extracted final percentage
                  console.log("Final percentage:", finalPercentage);
                  await setDoc(doc(db, "Feedback", email), {
                    feedback_details: feedback_detailed,
                    feedback_prct: finalPercentage,
                  });

                  setGlobalList((prevGlobalList) =>
                    prevGlobalList.filter((email) => email !== email)
                  );
                } else {
                  console.log("Final percentage not found in the phrase.");
                }
              } catch (error) {
                console.log("full_interview error", error);
              }
            } catch (error) {
              console.log("Feedback detailed error: ", error);
            }
          }
        });
      }
    );

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, []); // Empty dependency array signifies that this effect runs only once

  console.log(numOfPositions);

  return (
    <div className="relative isolate min-h-full">
      <div
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      {/* <div className="min-h-full"> */}
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-7"
                      src="https://i.ibb.co/GnW0bz3/ico-white.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "flex items-center rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <HomeIcon
                            className="h-5 w-5 mr-2"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <button
                      type="button"
                      className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.imageUrl}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium leading-none text-gray-400">
                      {user.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <header className="bg-white shadow flex justify-between items-center px-4 py-6">
          <div className="flex items-center space-x-2">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon
                className="h-9 w-9 cursor-pointer"
                aria-hidden="true"
              />
            </div>
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Performances :{" "}
              {position.charAt(0).toUpperCase() + position.slice(1)}
            </h1>
          </div>
          {/* <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-9 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => { window.location.reload(); }}

          >

            Reload
            <ArrowPathIcon className="h-5 w-5 ml-5" aria-hidden="true" />
          </button> */}
        </header>
      </div>

      <main>
        
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">

          <ul role="list" className="divide-y divide-gray-100">
            {[...Array(numOfPositions).keys()]
              .sort((a, b) => {
                const emailAExists = emails[a] in feedbackData;
                const emailBExists = emails[b] in feedbackData;
                const triageScoreForEmailA = dictInfo.find(
                  (item) => item.placeholder === emails[a]
                )?.TriageScore;
                const triageScoreForEmailB = dictInfo.find(
                  (item) => item.placeholder === emails[b]
                )?.TriageScore;

                if (emailAExists && emailBExists) {
                  return (
                    (parseInt(feedbackData[emails[b]].feedback_prct) +
                      parseInt(triageScoreForEmailB) -
                      (parseInt(feedbackData[emails[a]].feedback_prct) +
                        parseInt(triageScoreForEmailA))) /
                    2
                  );
                } else if (emailAExists && !emailBExists) {
                  return -1;
                } else if (!emailAExists && emailBExists) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map((index) => {
                const emailExistsInFeedback =
                  emails[index] && emails[index] in feedbackData;
                const feedbackPrct = emailExistsInFeedback
                  ? feedbackData[emails[index]].feedback_prct
                  : null;
                const feedbackDetails = emailExistsInFeedback
                  ? feedbackData[emails[index]].feedback_details
                  : null;
                // in the section below, of you want to add extract another field, tou should extract it in the definition of dictInfo in line 122
                const triageScoreForEmail = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.TriageScore;
                const ResumeForEmail = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.Resume;
                const ResumeNameForEmail = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.ResumeName;
                const State_ = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.State;
                const TriageDetails = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.TriageDetails;
                const Position = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.Position;
                const QA0 = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.QA0;
                const QA1 = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.QA1;
                const QA2 = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.QA2;
                const QA3 = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.QA3;
                const QA4 = dictInfo.find(
                  (item) => item.placeholder === emails[index]
                )?.QA4;

                const joinedText = `${QA0} ${QA1} ${QA2} ${QA3} ${QA4}`;

                return (
                  <li key={index} className="flex justify-between gap-x-6 py-5">
                    <div className="flex min-w-0 gap-x-4  items-center">
                      {emailExistsInFeedback ? (
                        <CheckCircleIcon className="h-7 w-7 flex-none rounded-full text-green-400" />
                      ) : globalList.includes(emails[index]) ? (
                        <ArrowPathIcon className="h-7 w-7 flex-none rounded-full text-orange-400" />
                      ) : (
                        <ClockIcon className="h-7 w-7 flex-none rounded-full text-gray-400" />
                      )}

                      <div className="min-w-0 flex-auto flex items-center justify-center">
                        <p className="text-l font-semibold leading-6 text-gray-900">
                          {emails[index]}
                        </p>
                      </div>
                    </div>

                    <div className="flex">
                      <div
                        className="flex items-center justify-center flex-col mr-9"
                        style={{ width: "150px" }}
                      >
                        <p className="text-xs font-bold leading-6 text-gray-900">
                          {State_ === "Sent" ? (
                            <span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                              Invitation delivered
                            </span>
                          ) : State_ === "Opened" ? (
                            <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              Invitation opened
                            </span>
                          ) : (
                            <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                              Email wasn't sent
                            </span>
                          )}
                        </p>
                        {/* <p className="text-xs font-semibold leading-6 text-gray-900">Current state</p> */}
                      </div>

                      <div
                        className="flex items-center justify-center flex-col mr-9"
                        style={{ width: "150px" }}
                      >
                        <p className="text-xs font-bold leading-6 text-gray-900">
                          {ResumeNameForEmail ? (
                            <span
                              className="truncate"
                              title={ResumeNameForEmail}
                            >
                              {ResumeNameForEmail.length > 25
                                ? `${ResumeNameForEmail.slice(0, 25)}...`
                                : ResumeNameForEmail}
                            </span>
                          ) : (
                            <span className="truncate">-</span>
                          )}
                        </p>
                        <p className="text-xs font-semibold leading-6 text-gray-900">
                          Resume File Name
                        </p>
                      </div>

                      <div className="flex items-center justify-center flex-col mr-9">
                        <p className="text-l font-bold leading-6 text-gray-900">
                          {triageScoreForEmail}%
                        </p>
                        <p className="text-xs font-semibold leading-6 text-gray-900">
                          Resume match
                        </p>
                      </div>
                      {emailExistsInFeedback ? (
                        <>
                          <div className="flex items-center justify-center flex-col mr-9">
                            <p className="text-l font-bold leading-6 text-gray-900">
                              {feedbackPrct}%
                            </p>
                            <p className="text-xs font-semibold leading-6 text-gray-900">
                              I.L Performance
                            </p>
                          </div>
                          <div className="flex items-center justify-center flex-col mr-9">
                            <p className="text-l font-bold leading-6 text-gray-900">
                              {(parseInt(triageScoreForEmail) +
                                parseInt(feedbackPrct)) /
                                2}
                              %
                            </p>
                            <p className="text-xs font-semibold leading-6 text-gray-900">
                              Overall performance
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex items-center justify-center flex-col mr-9">
                            <p className="text-l font-bold leading-6 text-gray-900">
                              -
                            </p>
                            <p className="text-xs font-semibold leading-6 text-gray-900">
                              I.L Performance
                            </p>
                          </div>
                          <div className="flex items-center justify-center flex-col mr-9">
                            <p className="text-l font-bold leading-6 text-gray-900">
                              -
                            </p>
                            <p className="text-xs font-semibold leading-6 text-gray-900">
                              Overall performance
                            </p>
                          </div>
                        </>
                      )}

                      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end justify-center">
                        <button
                          type="button"
                          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => {
                            navigate("/performancedetailed", {
                              state: {
                                feedbackDetails: feedbackDetails,
                                TriageDetails: TriageDetails,
                                triageScoreForEmail: triageScoreForEmail,
                                ResumeForEmail: ResumeForEmail,
                                Position: position,
                                feedbackPrct: feedbackPrct,
                                QAs: joinedText,
                                userEmail: emails[index],
                              },
                            });
                          }}
                        >
                          Details
                          <ArrowRightIcon
                            className="ml-2 mr-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </main>
    </div>
  );
}

export default PerformanceDashboard;
