import React, { useState } from 'react';
import person from './Assets/person.png';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, addDoc, collection, setDoc, doc, updateDoc, getDoc, getDocs, query, where } from 'firebase/firestore';
import termsAndConditions from './terms';


const EmailSubmission = () => {
    const attempt = async () => {
        try {
            const response = await axios.post(
                "https://openai-hirex.onrender.com/QGen",
                {
                    "position": "ML Engineer"
                    ,
                    "criteria": "1/Python 2 /Machine Learning Libraries Frameworks 3 / Data Processing and Analysis 4 / Database and Storage 5/ Experience in Javascript 6/ Study in sweden"
                    ,
                    "resume": "Modelization of a pulmonary bifurcation attacked by Covid19 – Supervised by Pr. Marcel FILOCHE, CNRS Research Director In a team of 4, we were conducted by Pr. Marcel Filoche in the Biomedical Engineering module to work on the visualization of a pulmonary bifurcation attacked by a Covid19 virus particle using python to do the calculations and visualization after feeding the program with equations such as stokes- einstein equation that describes the diffusion of spherical particles through a liquid with low Reynolds number and also leveraging the discretization technique of differential equations, in this project, I and another teammate were responsible for the making and implementing of the algorithms, all the physics equations and techniques needed and we made them as optimized as possible while the other two teammates were responsible for making plots to visualize the results we found with respect to the parameters we set previously. SmartCare – Supervised by Pr. Selwa ELFIRDOUSSI, Professor at Emines-UM6P, "
                    ,
                    "jobDesc": " role involves designing, developing, and deploying machine learning models and algorithms to address complex business challenges. Responsibilities include data preprocessing, feature engineering, model selection and tuning, and integrating machine learning solutions into production systems. ML Engineers collaborate with cross-functional teams to understand business requirements and translate them into scalable and efficient machine learning solutions that drive business value"
                    ,
                    "metrics": "1/ Technical Problem-solving 2 /Algorithmic Understanding 3 /Coding Proficiency 4 / Communication Skills"
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "mode": "no-cors"
                    },
                }
            );
            console.log("response \n")
            console.log(response.data.text)
            const questions = response.data.text.split(/\d+-/).filter(question => question.trim() !== "");
            const trimmedQuestions = questions.map(question => question.trim());
            console.log("Questions \n")

            console.log(trimmedQuestions)



        } catch (error) {
            console.log(error);
            alert(error)
        }

    }
    return (
        <div>
            <button onClick={attempt}> Hello</button>

        </div>
    );
};

export default EmailSubmission;