import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Switch,
} from "react-router-dom";
import Home from "./pages/Home";
import RecordingPage from "./pages/RecordingPage";
import LocalFeedback from "./pages/LocalFeedback";
import RecordingFeedback from "./pages/RecordingFeedback";
import Triage from "./pages/Triage";
import PositionSubmission from "./pages/PositionSubmission";
import Dashboard from "./pages/Dashboard";
import PositionDetail from "./pages/PositionDetail";
import EmailSubmission from "./pages/EmailSubmission";
import PerformanceDashboard from "./pages/PerformanceDashboard";
import LandingPage from "./pages/LandingPage";
import SubscribeNewsletter from "./pages/SubscribeNewsletter";
import PerformanceDetailed from "./pages/PerformanceDetailed";
import ShortList from "./pages/ShortList";
import InterviewInstructions from "./pages/InterviewInstructions";
import Login from "./pages/Login";
import CvMatchDetail from "./pages/CvMatchDetail";
import Request from "./pages/Request";
import { useEffect, useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

 

  return (
    <div className="App">
      <Router>
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/" exact element={<Dashboard />} />
              <Route
                path="/submission"
                exact
                element={<PositionSubmission />}
              />
              <Route path="/detail" exact element={<PositionDetail />} />
              <Route path="/triage" exact element={<Triage />} />
              <Route path="/email" exact element={<EmailSubmission />} />
              <Route
                path="/recordingfeedback"
                exact
                element={<RecordingFeedback />}
              />
              <Route
                path="/interviewinstructions"
                exact
                element={<InterviewInstructions />}
              />
              <Route
                path="/cv-match-detail"
                exact
                element={<CvMatchDetail />}
              />
              <Route
                path="/performance"
                exact
                element={<PerformanceDashboard />}
              />
              <Route
                path="/performancedetailed"
                exact
                element={<PerformanceDetailed />}
              />
              <Route path="/shortlist" exact element={<ShortList />} />
              <Route path="/request" exact element={<Request />} />
            </>
          ) : (
            <>
              <Route
                path="/subscribenewsletter"
                exact
                element={<SubscribeNewsletter />}
              />
              <Route path="/" exact element={<LandingPage />} />
              <Route path="/login" exact element={<Login />} />
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
};

export default App;

{
  /* const App = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/subscribenewsletter"
            exact
            element={<SubscribeNewsletter />}
          />
          <Route path="/landingpage" exact element={<LandingPage />} />
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/submission" exact element={<PositionSubmission />} />
          <Route path="/detail" exact element={<PositionDetail />} />
          <Route path="/triage" exact element={<Triage />} />
          <Route path="/email" exact element={<EmailSubmission />} />
          <Route path="/login" exact element={<Login />} />
          <Route
            path="/recordingfeedback"
            exact
            element={<RecordingFeedback />}
          />
          <Route
            path="/interviewinstructions"
            exact
            element={<InterviewInstructions />}
          />
          <Route path="/cv-match-detail" exact element={<CvMatchDetail />} />
          <Route path="/performance" exact element={<PerformanceDashboard />} />
          <Route
            path="/performancedetailed"
            exact
            element={<PerformanceDetailed />}
          />

          <Route path="/shortlist" exact element={<ShortList />} />

          <Route path="/request" exact element={<Request />} />

        </Routes>
      </Router>
    </div>
  );
}; 

export default App;
 */
}
